import {initializeApp} from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDHBqIqneLBYB7H24W_4qEXUGNp3C9F_wA",
  authDomain: "portfolio-63d9f.firebaseapp.com",
  projectId: "portfolio-63d9f",
  storageBucket: "portfolio-63d9f.appspot.com",
  messagingSenderId: "1006732471532",
  appId: "1:1006732471532:web:8a77bdbc4852724826c9c9",
  measurementId: "G-N308CYH8G3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch(e) {
    console.error(e);
  }
};

const logOut = () => {
  signOut(auth);
};

export {auth, db, logIn, logOut};